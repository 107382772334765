import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-promotions',
    templateUrl: './promotions.component.html',
    styleUrls: ['./promotions.component.css']
})
export class PromotionsComponent implements OnInit {

    promotions: any[] = [];
    apetizers: any[] = [];

    constructor() { }

    ngOnInit() {
        this.promotions = [
            {
                sectionTitle: 'REGULAR',
                sectionType: 'Promotion',
                sectionDescription: '',
                sectionPrice: '',
                order: 1,
                items: [
                    {
                        title: 'Small Room (Up to 6 people)',
                        description: '',
                        price: '$20/hour',
                        order: 1
                    },
                    {
                        title: 'Medium Room (Up to 11 people)',
                        description: '',
                        price: '$30/hour',
                        order: 2
                    },
                    {
                        title: 'Large Room (Up to 24 people) *',
                        description: '',
                        price: '$50/hour',
                        order: 3
                    },
                    {
                        title: 'VIP Room (Up to 30 people) *',
                        description: '',
                        price: '$60/hour',
                        order: 4
                    },
                ]
            },
            {
                sectionTitle: 'HAPPY HOUR',
                sectionType: 'Promotion',
                sectionDescription: '',
                sectionPrice: '',
                order: 1,
                items: [
                    {
                        title: 'Small Room (Up to 6 people)',
                        description: '',
                        price: '$10/hour',
                        order: 1
                    },
                    {
                        title: 'Medium Room (Up to 11 people)',
                        description: '',
                        price: '$15/hour',
                        order: 2
                    },
                    {
                        title: 'Large Room (Up to 24 people) *',
                        description: '',
                        price: '$25/hour',
                        order: 3
                    },
                    {
                        title: 'VIP Room (Up to 30 people) *',
                        description: '',
                        price: '$30/hour',
                        order: 4
                    },
                ]
            },
            {
                sectionTitle: 'BOTTLED BEER AND SOJU - Buy 2 Get 1 FREE',
                sectionType: 'Special',
                sectionDescription: '',
                sectionPrice: '',
                order: 0
            },
            {
                sectionTitle: '5 Domestic Beers & 1 Appetizer',
                sectionType: 'Special',
                sectionDescription: '',
                sectionPrice: '$30',
                order: 1
            },
            {
                sectionTitle: '5 Imported Beers & 1 Appetizer',
                sectionType: 'Special',
                sectionDescription: '',
                sectionPrice: '$35',
                order: 1
            }
        ];

        this.apetizers = [
            {
                "id": 1,
                "category": "Appetizers",
                "subcategory": "",
                "title": "Chicken or Cheese Quesadillas (8 pcs)",
                "description": "Served with salsa & Sour Cream",
                "korean_description": "",
                "price": "7",
                "pic": "",
                "subitems": [

                ]
            },
            {
                "id": 2,
                "category": "Appetizers",
                "subcategory": "",
                "title": "Pan Fried Potstickers (6 pcs)",
                "description": "Stuffed Chicken Dumplings",
                "korean_description": "",
                "price": "7",
                "pic": "",
                "subitems": [

                ]
            },
            {
                "id": 3,
                "category": "Appetizers",
                "subcategory": "",
                "title": "Teriyaki Sliders (3 pcs)",
                "description": "Angus Beef Burgers or Chicken Breast with Teriyaki Sauce & Japanese Kewpie Mayo",
                "korean_description": "",
                "price": "7",
                "pic": "",
                "subitems": [

                ]
            },
            {
                "id": 4,
                "category": "Appetizers",
                "subcategory": "",
                "title": "Chicken Wings - Traditional or Boneless (6 pcs)",
                "description": "Marinated with Teriyaki, BBQ, Honey Garlic, Spicy Sweet & Sour, or Hot Sauce",
                "korean_description": "",
                "price": "7",
                "pic": "",
                "subitems": [

                ]
            },
            {
                "id": 5,
                "category": "Appetizers",
                "subcategory": "",
                "title": "Fried Shrimp (6 pcs)",
                "description": "Served with Cocktail Sauce",
                "korean_description": "",
                "price": "7",
                "pic": "",
                "subitems": [

                ]
            },
            {
                "id": 6,
                "category": "Appetizers",
                "subcategory": "",
                "title": "Nachos",
                "description": "Beef or Chicken",
                "korean_description": "",
                "price": "10",
                "pic": "",
                "subitems": [

                ]
            },
            {
                "id": 7,
                "category": "Appetizers",
                "subcategory": "",
                "title": "Mozzarella Sticks (6 pcs)",
                "description": "Served with Marinara Sauce",
                "korean_description": "",
                "price": "6",
                "pic": "",
                "subitems": [

                ]
            },
            {
                "id": 8,
                "category": "Appetizers",
                "subcategory": "",
                "title": "Jalapeno Poppers (6 pcs)",
                "description": "Served with Ranch Dressing",
                "korean_description": "",
                "price": "6",
                "pic": "",
                "subitems": [

                ]
            },
            {
                "id": 9,
                "category": "Appetizers",
                "subcategory": "",
                "title": "Fried Cauliflower (6 pcs)",
                "description": "Served with Ranch Dressing",
                "korean_description": "",
                "price": "6",
                "pic": "",
                "subitems": [

                ]
            },
            {
                "id": 10,
                "category": "Appetizers",
                "subcategory": "",
                "title": "Sweet Potato Fries",
                "description": "",
                "korean_description": "",
                "price": "5",
                "pic": "",
                "subitems": [

                ]
            },
            {
                "id": 11,
                "category": "Appetizers",
                "subcategory": "",
                "title": "Hand Cut French Fries",
                "description": "",
                "korean_description": "",
                "price": "3",
                "pic": "",
                "subitems": [
                    {
                        "title": "w/ Cheese",
                        "description": "",
                        "price": "4"
                    },
                    {
                        "title": "w/ Chili & Cheese",
                        "description": "",
                        "price": "6"
                    },
                    {
                        "title": "w/ Bulgogi",
                        "description": "",
                        "price": "10"
                    }
                ]
            },
            {
                "id": 12,
                "category": "Appetizers",
                "subcategory": "",
                "title": "Popcorn Chicken",
                "description": "Includes Choice of 2 Sauces: BBQ, Ranch, Honey Garlic, Teriyaki, Sweet & Hot, Hot Sauce",
                "korean_description": "",
                "price": "10",
                "pic": "",
                "subitems": [

                ]
            }
        ]
    }

}
